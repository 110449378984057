const attrs = {
  NAME: "name",
};
export class AppSlot extends HTMLElement {
  static observedAttributes = [attrs.NAME];

  private module: any;
  private isMounted = false;

  connectedCallback() {
    // console.log("Custom element added to page.");
    this.isMounted = true;
  }

  disconnectedCallback() {
    // console.log("Custom element removed from page.");
    this.isMounted = false;
  }

  adoptedCallback() {
    console.log("Custom element moved to new page.");
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    console.log(`Attribute ${name} has changed.`, oldValue, newValue);
    if (oldValue) this.unmount();

    // console.log("importing ", newValue);
    import(newValue)
      .then((module) => {
        // console.log("module is", module);
        const name = newValue
          .replace("@", "")
          .replaceAll("/", "_")
          .replaceAll("-", "_");
        this.module = module.c11Module || module[name] || module.default;
        //TODO: handle module is null before calling mount.
        this.mount();
      })
      .catch((e) => {
        console.error("failed to load module", newValue, e);
      });
  }

  unmount() {
    if (!this.isMounted || !this.module) {
      return;
    }
    this.module.unmount();
    this.isMounted = true;
  }

  mount() {
    // console.log(this.isMounted, this.module);
    if (!this.isMounted || !this.module) {
      return;
    }
    // console.log("Mounting...", this.module, this.module.mount, this);
    this.module.mount({ mountElement: this });
  }
}

export const register = () => {
  window.customElements.define("app-slot", AppSlot);
};

declare global {
  interface HTMLElementTagNameMap {
    "app-slot": AppSlot;
  }
}
