export * as AppSlot from "./AppSlot";
export { bootstrap } from "./bootstrap";
// capabilities
// - storage (cache)
// - persistent db (indexdb)
// - routing (location, history, etc)
// - monitoring (app monitoring/capture exceptions, user monitoring/capture intentions, posthog etc)
// - feature flagging (activiation/dezactivation)
// - event stream (coordination between apps/important events)
// - theme
// - layouts (partials, content/partials/common components)
// - microfontend loading

//@ts-ignore
window.process = {
  env: {},
};
// add router

// login oidc
