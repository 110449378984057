import * as AppSlot from "./AppSlot";

// feature flagging - isn't the app responsible for this?
// service worker calls an installer for the app - the installer is reponsibile
// for putting the file content into local storage and storing meta information
// in indexdb - the app is responsible for validating or invalidating its cache
// service worker serves files from local storage / goes to network if necessary
// micro-app
// mount, unmount (is bootstrapping ever needed? what kind of preparations would be needed)
// the minimum index.html head setup for PWA
// https://vite-pwa-org.netlify.app/guide/pwa-minimal-requirements.html#entry-point

const emptyModule = (key: string) => ({
  key,
  module: {
    bootstrap: () => {
      console.log("[app-shell]: empty module (%s) bootstrap", key);
    },
    mount: () => {
      console.log("[app-shell]: empty module (%s) mount", key);
    },
  },
});

let isBootstraped = false;
export const bootstrap = async (configs: any) => {
  if(isBootstraped) return;
  isBootstraped = true;

  window.process = {
    env: configs.env || {},
  };
  configs.env = undefined;

  AppSlot.register();

  console.log("[app-shell] initializing...");
  const modules = await Promise.all(
    Object.keys(configs).map(async (key: string) => {
      console.log("[app-shell] Loading %s...", key);
      return import(key)
        .then((module) => {
          console.log("[app-shell] Loaded '%s'.", key);
          return {
            key,
            module,
          };
        })
        .catch((e) => {
          console.error("[app-shell] Could not load '%s'.", key, e);
          return emptyModule(key);
        });
    })
  );

  //bootstrap
  await Promise.all(
    modules.map((bundle) => bundle.module.bootstrap(configs[bundle.key]))
  );
  console.log("[app-shell] mounting...");
  // mount
  await Promise.all(
    modules.map((bundle) => bundle.module.mount?.(document.body))
  );

  console.log("[app-shell] done.");
};
